<!--
 * @Description:第五步  房源照片
 * @Author: 琢磨先生
 * @Date: 2022-05-25 08:54:20
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-03-12 18:06:24
-->

<template>
  <el-scrollbar>
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      label-width="140px"
      label-position="top"
    >
      <div v-for="item in types" :key="item.value">
        <div v-if="item.value == 1">
          <el-form-item :label="`卧室${i}`" :key="i" v-for="i in form.bedroom">
            <div class="pic_list">
              <draggable
                item-key="id"
                v-model="item.images[i - 1]"
                group="people"
                class="draggable_group"
              >
                <template #item="{ element, index }">
                  <div class="image">
                    <el-image
                      :src="element"
                      fit="scale-down"
                      :preview-src-list="item.images"
                      :initial-index="index"
                    ></el-image>
                    <div class="btn_del">
                      <el-icon
                        class="icon-del"
                        @click="delImage(item, index, i - 1)"
                        ><Close
                      /></el-icon>
                    </div>
                  </div>
                </template>
              </draggable>
              <image-space
                :hook="{ value: item.value, ext_no: i - 1 }"
                :folder="folder"
                :count="maxCount - item.images.length"
                @change="chooseChange"
              ></image-space>
            </div>
            <div class="tip help-block">
              图片要求：宽高均大于480px，大小8M内。已上传{{
                item.images.length
              }}/{{ maxCount }}张，可拖拽调整位置。
            </div>
          </el-form-item>
        </div>
        <el-form-item
          v-if="!item.is_disabled && item.value != 1"
          :label="item.text"
        >
          <div class="pic_list">
            <draggable
              item-key="id"
              v-model="item.images"
              group="people"
              class="draggable_group"
            >
              <template #item="{ element, index }">
                <div class="image">
                  <el-image
                    :src="element"
                    fit="scale-down"
                    :preview-src-list="item.images"
                    :initial-index="index"
                  ></el-image>
                  <div class="btn_del">
                    <el-icon class="icon-del" @click="delImage(item, index)"
                      ><Close
                    /></el-icon>
                  </div>
                </div>
              </template>
            </draggable>
            <image-space
              :hook="{ value: item.value, ext_no: 0 }"
              :folder="folder"
              :count="maxCount - item.images.length"
              @change="chooseChange"
            ></image-space>
          </div>
          <div class="tip help-block">
            图片要求：宽高均大于480px，大小8M内。已上传{{
              item.images.length
            }}/{{ maxCount }}张，可拖拽调整位置。
          </div>
        </el-form-item>
      </div>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" :loading="saving"
          >下一步</el-button
        >
      </el-form-item>
    </el-form>
  </el-scrollbar>
</template>

<script>
import common_api from "@/http/common_api";
import ImageSpace from "../../psd/original_multiple_upload.vue";
import draggable from "vuedraggable";
export default {
  components: {
    ImageSpace,
    draggable,
  },
  data() {
    return {
      loading: false,
      saving: false,
      drag: false,
      maxCount: 99,
      //照片类型
      types: [],
      form: {},
      rules: {},
      //文件目录
      folder: {},
      fileList: [],
    };
  },
  emits: ["change"],
  props: ["item"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.form = Object.assign({}, this.item);
          if (this.item.id) {
            this.createFolder();
            common_api.get_enums().then((res) => {
              if (res.code == 0) {
                this.types = res.data.house_image_types;
                this.types.forEach((x) => {
                  x.images = [];
                  x.images = this.form.images
                    .filter((o) => o.type == x.value)
                    .map((x) => x.pic_url);
                  //没有厨房
                  this.is_disabled = this.form.kitchen == 0 && x.value == 2;
                  //没有阳台
                  if (this.form.balcony == 0) {
                    if (x.value == 4) {
                      x.is_disabled = true;
                    }
                  }
                  //卧室
                  if (x.value == 1) {
                    x.images = [];
                    for (var i = 0; i < this.form.bedroom; i++) {
                      x.images[i] = this.form.images
                        .filter((o) => o.type == x.value && o.ext_no == i)
                        .map((x) => x.pic_url);
                    }
                  }
                });
              }
            });
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(this.form);

          var images = [];
          this.types.forEach((item) => {
            if (item.value != 1) {
              item.images.forEach((img) => {
                images.push({
                  type: item.value,
                  pic_url: img,
                });
              });
            } else {
              for (var i = 0; i < this.form.bedroom; i++) {
                item.images[i].forEach((img) => {
                  images.push({
                    type: item.value,
                    pic_url: img,
                    ext_no: i,
                  });
                });
              }
            }
          });
          if (images.length == 0) {
            this.$message({
              type: "warning",
              message: "请上传图片",
            });
            return;
          }
          this.form.images = images;

          this.saving = true;
          this.$http
            .post("seller/v1/house/edit/image", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.$emit("change", "image");
              }
              this.saving = false;
            });
        }
      });
    },
    /**
     * 创建房源文件库文件夹
     */
    createFolder() {
      this.$http
        .post("seller/v1/psd/folder/edit", {
          name: `房源_${this.item.id}`,
        })
        .then((res) => {
          if (res.code == 0) {
            this.folder = res.data;
            // console.log(this.folder);
          }
        });
    },
    /**
     * 图片上传成功后回调
     */
    chooseChange(file, hook) {
      var item = this.types.find((o) => o.value == hook.value);
      if (item) {
        if (item.value == 1) {
          item.images[hook.ext_no].push(file.file_url);
        } else {
          item.images.push(file.file_url);
        }
      }
    },
    /**
     * 删除图片
     */
    delImage(item, i, k) {
      if (item.value == 1) {
        item.images[k].splice(i, 1);
      } else {
        item.images.splice(i, 1);
      }
    },
    /**
     * 拖拽结束
     */
    onDragEnd() {},
  },
};
</script>
<style  scoped>
/* .image__preview {
  line-height: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0;
}
.thumb {
  position: relative;
  font-size: 0;
  line-height: 0;
  margin-right: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 6px;
}
.thumb:hover .actions {
  opacity: 1;
}
.thumb .el-image {
  font-size: 0;
  width: 148px;
  height: 148px;
}
.thumb .actions {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 40px;
  line-height: 40px;
  z-index: 1;
  background-color: var(--el-overlay-color-lighter);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.3s;
}
.thumb .actions .el-icon {
  font-size: 20px;
  color: white;
  cursor: pointer;
} */
/* 拖拽组 */
.draggable_group {
  display: flex;
  flex-flow: wrap;
}

/* 图片列表 */
.pic_list {
  width: 100%;
  display: flex;
  flex-flow: wrap;
}
.pic_list .tip {
  width: 100%;
}
.pic_list .image {
  margin: 0 10px 10px 0;
  width: 160px;
  height: 120px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.pic_list .image .el-image {
  border-radius: 5px;
}

.pic_list .image .btn_del {
  position: absolute;
  right: 0px;
  top: 0px;
  background: var(--el-color-danger);
  color: white;
  height: 15px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 5px;
}
.pic_list .image .el-icon {
  font-size: 12px;
}

.pic_list .image:hover .icon-del.el-icon {
  display: block;
}
</style>